$(function(){
    // $(".fold-table tr.view").on("click", function(){
    //   $(this).toggleClass("open").next(".fold").toggleClass("open");
    // });
    //$("#myModalAcc").modal("hide");
  });
  $(function() {
    $('#form .input-daterange').datepicker({
        startDate: "0d",
        autoclose: true,
        orientation: "top left",
        todayHighlight: true
    });
});